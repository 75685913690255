import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@ayx/onyx-ui';

export default function Footer() {
	const alteryxDomain = 'https://www.alteryx.com';
	const { i18n } = useTranslation();

	const privacyPolicyLink = useMemo(() => {
		switch (i18n.language) {
			case 'en':
				return `${alteryxDomain}/privacy-policy`;
			default:
				return `${alteryxDomain}/${i18n.language}/privacy-policy`;
		}
	}, [i18n.language]);

	return (
		<div className="bg-white py-5 text-center">
			<Link href={privacyPolicyLink}>
				<Trans>Privacy Policy</Trans>
			</Link>
		</div>
	);
}
