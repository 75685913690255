const paths = {
	// utility
	root: '/',
	wildcard: '*',

	// errors
	notFound: '/404',
	error: '/error',
	unauthorized: '/login',
	unauthorizedExternalUser: '/unauthorized',

	// auth utilities
	authorize: '/authorize',
	logout: '/logout',

	// pages
	account: '/account',
	landing: '/landing',
	assessment: '/assessment',
	report: '/report',
	version: '/version',
};

export default paths;
